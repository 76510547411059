import React, { useState } from "react";
import { Layout, Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import TLanguageSelector from "../LanguageSelector";
import { t } from "../../utilities/Message";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import { MENU_OPTIONS } from "./Constants";

import { ROUTES_GENERAL } from "../../utilities/Constant";
import "./style.scss";
import TButtonLink from "../ButtonLink";

const { Header } = Layout;

interface HeaderProps {
  className?: string;
}

const THeader = (props: HeaderProps) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const history = useHistory();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onNavigate = (menu) => {
    const { route, path } = menu;

    const container = document.getElementById(path);
    if (container) {
      window.scrollTo({
        top: container.offsetHeight + 400,
        behavior: "smooth",
      });
    } else {
      history.push(route);
    }
  };

  const _onHandleUpload = () => {
    history.push(ROUTES_GENERAL.HOME_PAGE);
    const btnUpload = document.getElementById("upload-button");
    if (btnUpload) {
      setTimeout(() => {
        btnUpload.click();
      }, 0);
    }
  };

  return (
    <>
      <Header className="header">
        <div
          className="header__logo"
          onClick={() => history.push(ROUTES_GENERAL.HOME_PAGE)}
        >
          <img
            width={160}
            src={pathServer.PATH_LOGO + "logo_white.svg"}
            className="d-flex pointer"
            alt="logo"
          />
        </div>
        <Menu
          className="header__menu"
          mode="horizontal"
          style={{ width: "400px" }}
        >
          {MENU_OPTIONS.map((menu, index) => (
            <Menu.Item
              key={menu.id}
              className={
                "header__menu-item " +
                (menu.route === location.pathname
                  ? " header__menu-selected"
                  : "")
              }
              onClick={() => onNavigate(menu)}
            >
              {t(menu.message, "Lorem")}
            </Menu.Item>
          ))}
        </Menu>
        <div className="header__cta">
          <Link
            className="d-flex header__tu"
            to={{ pathname: ROUTES_GENERAL.TU_COM }}
            target="_blank"
          >
            Conoce TU
            <ReactSVG
              src={pathServer.PATH_ICONS + "ic_tu.svg"}
              className="icon"
            />{" "}
          </Link>
          <TLanguageSelector />
          <TButtonLink onClick={_onHandleUpload} name=" ¡Prueba ahora!" />
        </div>
        <div className="header__mobile-menu">
          <img
            onClick={() => onNavigate(ROUTES_GENERAL.HOME_PAGE)}
            width={180}
            src={pathServer.PATH_LOGO + "logo_white.svg"}
            className="d-flex pointer"
            alt="logo"
          />
          <Button
            className="header__mobile-menu-button"
            type="text"
            icon={
              <MenuOutlined style={{ color: "white", fontSize: "1.5rem" }} />
            }
            onClick={showDrawer}
          />
          <Drawer placement="right" onClose={onClose} open={visible}>
            <Menu className="header__drawer-menu" theme="light" mode="vertical">
              {MENU_OPTIONS.map((menu) => (
                <Menu.Item
                  key={menu.id}
                  className={
                    "header__drawer-menu-item " +
                    (menu.route === location.pathname
                      ? " header__menu-selected"
                      : "")
                  }
                  onClick={() => onNavigate(menu.route)}
                >
                  {t(menu.message, "")}
                </Menu.Item>
              ))}
            </Menu>
          </Drawer>
        </div>
      </Header>
    </>
  );
};

THeader.displayName = "THeader";

THeader.defaultProps = {
  className: "",
};

export default THeader;

// Mockup endpoint
let URL_MOCKUP_BASE = "http://demo7088036.mockable.io/";

let RSA_PK = "";
let WEB_PRK = "";
let WEB_PUK = "";

export const setRsaPk = (val) => (RSA_PK = val);
export const GET_RSA_PK = () => `${RSA_PK}`;
export const setWebPrk = (val) => (WEB_PRK = val);
export const GET_WEB_PRK = () => `${WEB_PRK}`;
export const setWebPuk = (val) => (WEB_PUK = val);
export const GET_WEB_PUK = () => `${WEB_PUK}`;

//Photo
const URL_ENDPOINT_PHOTOS = () => `${URL_MOCKUP_BASE}photos`;

export default {
  URL_ENDPOINT_PHOTOS,
  GET_WEB_PRK,
  GET_RSA_PK,
};

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES_GENERAL, ROUTES_GENERAL_ESP } from "../../utilities/Constant";
import { t } from "../../utilities/Message";
import { pathServer } from "../../utilities/Function";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { Button, Input, Form, Checkbox, Space, message } from "antd";
import { ReactSVG } from "react-svg";
import TLanguageSelector from "../LanguageSelector";
import moengage from "@moengage/web-sdk";
import "./style.scss";
import { LINKS_SOCIAL } from "./Constants";
import TContactModal from "../ForgotPasswordModal";

interface FooterProps {
  className?: string;
}

const EVENT_SUBSCRIBE = "formulario_completado";
const EVENT_SUBSCRIBE_ATTRIBUTES = {
  producto_formulario: "tu",
  pagina_origen_formulario: "verifai",
  objetivo_formulario: "newsletter",
};

const TFooter = (props: FooterProps) => {
  const { locale } = useContext(LanguageContext);
  const [hasSubscribe, setHasSubscribe] = useState(false);
  const lang_moengage = locale === "es" ? "spanish" : "english";
  const [contact, setContact] = useState({ showModal: false, loading: false });
  const [messageApi, contextHolder] = message.useMessage();

  const messagesResponse = {
    error: t("app.Landing.CTA.MessageFailed"),
    success: t("app.Landing.CTA.Message"),
  };

  const onSubmitCTA = () => {
    setContact({ ...contact, loading: true });
    messageApi.open({
      type: "success",
      content: messagesResponse.success,
    });
    setTimeout(() => {
      setContact({ ...contact, showModal: false, loading: false });
      return;
    }, 1000);
  };

  const onSubscribe = async (value) => {
    const { email } = value;
    try {
      await moengage
        .add_unique_user_id(email)
        .then(() => moengage.add_email(email))
        .then(() =>
          moengage.track_event(EVENT_SUBSCRIBE, {
            ...EVENT_SUBSCRIBE_ATTRIBUTES,
            idioma_formulario: locale,
          })
        )
        .then(() => moengage.add_user_attribute(EVENT_SUBSCRIBE, "true"))
        .then(() =>
          moengage.add_user_attribute("newsletter_alta_" + lang_moengage, true)
        )

        .then(() => moengage.destroy_session());

      setHasSubscribe(true);
    } catch (e) {
      console.error("MoEngage SDK no está disponible: ", e);
      setHasSubscribe(false);
    }
  };

  return (
    <footer className="footer max-padding">
      {contextHolder}

      <div className="footer__header">
        <div className="footer__header-content">
          <div className="d-flex space-between">
            <img
              src={pathServer.PATH_LOGO + "logo_white.svg"}
              alt="veriFAI"
              width={200}
              className="footer__header-logo"
            />
            <TLanguageSelector />
          </div>
          <p className="mt-1">{t("app.Landing.Footer.title2.description")}</p>
          <div className="d-flex mt-0">
            <p className="full-center ">
              {t("app.Landing.Footer.title3.description")}{" "}
            </p>
            <img
              className="ml-0 "
              src={pathServer.PATH_LOGO + "logo_tu.svg"}
              alt="veriFAI"
              width={30}
            />
          </div>{" "}
        </div>
        <div className="footer__header-cta">
          <p className="mb-0">{t("app.Landing.Footer.title1.contact")}</p>
          {hasSubscribe ? (
            <>
              <p className="text-center mt-1 mb-0">
                {t("app.Landing.Footer.CheckBoxMessage")}
              </p>
              <p className="text-center mb-0">
                {t("app.Landing.Footer.CheckBoxMessage2")}
              </p>
            </>
          ) : (
            <Form
              name="email_form"
              className="email_form"
              onFinish={onSubscribe}
            >
              <Space className="email_form-email">
                <Form.Item
                  name="email"
                  label=""
                  rules={[
                    {
                      type: "email",
                      message: t("app.Landing.Footer.emailValidate"),
                    },
                    {
                      required: true,
                      message: t("app.Landing.Footer.emailValidate"),
                    },
                  ]}
                >
                  <Input
                    className="email_input"
                    size="large"
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item className="btn-cta-container">
                  <Button
                    type="link"
                    size="large"
                    className=" btn-cta"
                    htmlType="submit"
                  >
                    {t("app.Landing.Footer.btnSend")}
                  </Button>
                </Form.Item>
              </Space>
              <Space className="checkbox">
                <Form.Item
                  name="terms"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              t("app.Landing.Footer.emailValidate")
                            ),
                    },
                  ]}
                >
                  <Checkbox className="mr-0">
                    {locale === "en" ? (
                      <div>
                        I have read and accept the{" "}
                        <a
                          href={ROUTES_GENERAL.PRIVACY}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          Privacy Policy{" "}
                        </a>
                      </div>
                    ) : (
                      <div>
                        He leído y acepto la
                        <a
                          href={ROUTES_GENERAL_ESP.PRIVACY}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Política de Privacidad{" "}
                        </a>
                      </div>
                    )}
                  </Checkbox>
                </Form.Item>
              </Space>
            </Form>
          )}

          <p className="mt-1 mb-0 footer_follow">
            {t("app.Landing.Footer.title1.contact.follow")}
          </p>
          <div className="footer__social">
            <Link
              className="my-auto"
              target="_blank"
              to={{
                pathname: LINKS_SOCIAL.facebook,
              }}
            >
              <ReactSVG
                src={pathServer.PATH_ICONS + "ic_facebook.svg"}
                className="icon-small"
              />
            </Link>
            <Link
              target="_blank"
              to={{
                pathname: LINKS_SOCIAL.twitter,
              }}
            >
              <ReactSVG
                src={pathServer.PATH_ICONS + "ic_twitter.svg"}
                className="icon-medium"
              />
            </Link>
            <Link
              target="_blank"
              to={{
                pathname: LINKS_SOCIAL.instagram,
              }}
            >
              <ReactSVG
                src={pathServer.PATH_ICONS + "ic_instagram.svg"}
                className="icon-large"
              />
            </Link>
            <Link
              target="_blank"
              to={{
                pathname: LINKS_SOCIAL.youtube,
              }}
            >
              <ReactSVG
                src={pathServer.PATH_ICONS + "ic_youtube.svg"}
                className="icon-large"
              />
            </Link>
            <Link
              target="_blank"
              to={{
                pathname: LINKS_SOCIAL.tiktok,
              }}
            >
              <ReactSVG
                src={pathServer.PATH_ICONS + "ic_tiktok.svg"}
                className="icon-medium"
              />
            </Link>

            <Link
              target="_blank"
              to={{
                pathname: LINKS_SOCIAL.feed,
              }}
            >
              <ReactSVG
                src={pathServer.PATH_ICONS + "ic_feed.svg"}
                className="icon-medium"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer__content-contact mt-2 ">
        <Link
          className="d-flex header__tu"
          to={{ pathname: ROUTES_GENERAL.TU_COM }}
          target="_blank"
        >
          {t("app.Landing.Header.TU")}
          <ReactSVG
            src={pathServer.PATH_ICONS + "ic_tu.svg"}
            className="icon icon-link "
          />{" "}
        </Link>
        <p
          className="d-flex header__tu pointer"
          onClick={() => setContact({ ...contact, showModal: true })}
        >
          {t("app.Landing.CTA.ButtonFooter")}
        </p>
      </div>
      <div className="footer__content ">
        <Link
          target="_blank"
          to={{
            pathname:
              locale === "en"
                ? ROUTES_GENERAL.CONDITIONS_EN
                : ROUTES_GENERAL.CONDITIONS,
          }}
        >
          {t("app.Landing.Footer.title1.conditions")}
        </Link>
        <Link
          to={{
            pathname:
              locale === "en"
                ? ROUTES_GENERAL.CONDITIONS_V2_EN
                : ROUTES_GENERAL.CONDITIONS_V2,
          }}
          target="_blank"
        >
          {t("app.Landing.Footer.title1.particular")}
        </Link>
        <Link
          to={{
            pathname:
              locale === "en" ? ROUTES_GENERAL.LEGAL : ROUTES_GENERAL_ESP.LEGAL,
          }}
          target="_blank"
        >
          {t("app.Landing.Footer.title1.legal")}
        </Link>

        <Link
          to={{
            pathname:
              locale === "en"
                ? ROUTES_GENERAL.PRIVACY
                : ROUTES_GENERAL_ESP.PRIVACY,
          }}
          target="_blank"
        >
          {t("app.Landing.Footer.title1.policy")}
        </Link>

        <Link
          to={{
            pathname:
              locale === "en"
                ? ROUTES_GENERAL.COOKIES
                : ROUTES_GENERAL_ESP.COOKIES,
          }}
          target="_blank"
        >
          {t("app.Landing.Footer.title1.cookies")}
        </Link>

        <a>
          <span className="ot-sdk-show-settings">
            {t("app.Landing.Footer.title1.configurateCookies")}
          </span>
        </a>
      </div>

      <div className="footer__brand mt-1">
        <p className="text-center" style={{ color: "#b0b6ca" }}>
          {" "}
          © Telefónica Innovación Digital S.L.U. Todos los derechos reservados
        </p>
      </div>
      {contact.showModal && (
        <TContactModal
          loading={contact.loading}
          visible={contact.showModal}
          onSubmit={onSubmitCTA}
          onCancel={() => setContact({ ...contact, showModal: false })}
        />
      )}
    </footer>
  );
};

TFooter.displayName = "TFooter";

TFooter.defaultProps = {
  className: "",
};

export default TFooter;

import React, { useContext, useEffect } from "react";
import { VerifaiContext } from "../../context/ws/VerifaiProvider";

import { withLanding } from "../../hocs/withLanding";

import TBanner from "../../components/Banner";
import THero from "../../components/Hero";
import { t } from "../../utilities/Message";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { message } from "antd";

import { useGetModels } from "../../hooks/useGetModels";
import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

import TCarouselV2 from "../../components/CarouselV2";
import "./styles.scss";

const HomePage = () => {
  const { locale } = useContext(LanguageContext);
  const { state } = useLocation();

  const [dataModels, loadingModels] = useGetModels();

  const history = useHistory();
  const { uploadData, loading, error } = useContext(VerifaiContext);
  const [messageApi, contextHolder] = message.useMessage();

  const HeroProps = {
    title: t("app.Landing.Hero.Title"),
    description: t("app.Landing.Hero.Description"),
    image: "images/hero_video.png",
    footer: t("app.Landing.Hero.Footer"),
  };

  const uploadFile = async (params: File) => {
    const content_type = localStorage.getItem("typeFile"); //image,video
    if (content_type) {
      const algorithm_list = dataModels[content_type]
        .map((motor) => motor.id)
        .join(",");

      try {
        const response = await uploadData(params, algorithm_list);

        if (!response.id) return;
        if (response) {
          history.push(
            ROUTES_GENERAL.RESULT +
              "/" +
              response.id +
              "/" +
              response.guest_token
          );
        }
      } catch (e) {}
    }

    return;
  };

  useEffect(() => {
    //@ts-ignore
    if (state?.errorPayment) {
      messageApi.destroy();
      //@ts-ignore
      messageApi.error(state?.errorPayment);
    }
    if (error) {
      messageApi.destroy();
      messageApi.error(error.message);
    }
  }, [error, messageApi, state]);

  useEffect(() => {
    page_view({ page_type: EVENTS_TGM.home, locale });
  }, []);

  return (
    <div className="home-page">
      {contextHolder}
      <TBanner uploadFile={uploadFile} loading={loadingModels || loading} />
      <THero {...HeroProps} />
      <TCarouselV2 />
    </div>
  );
};

export default withLanding(HomePage);

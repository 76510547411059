import { Container } from "inversify";
import "reflect-metadata";

import {
  LIST_PHOTO_USE_CASE,
  ListPhotoUseCase,
  ListPhotoUseCaseImpl,
} from "../domain/usecases/PhotoUseCase";

import {
  PHOTO_REPOSITORY,
  PhotoRepository,
} from "../domain/repositories/PhotoRepository";
import PhotoRepositoryImpl from "../infraestructure/repositories/PhotoRepository";
import { IMAGE_PORT, ImagePort } from "../domain/port/inbound/ImagePort";
import { ImageAdapter } from "../infraestructure/adapters/ImageAdapter";
import { GTMPort, GTM_PORT } from "../domain/port/inbound/GTMPort";
import { GTMAdapter } from "../infraestructure/adapters/GTMAdapter";

let container = new Container();

//Repositories
const bindRepositories = () => {
  container.bind<PhotoRepository>(PHOTO_REPOSITORY).to(PhotoRepositoryImpl);
};

bindRepositories();

//Use Cases
const bindUseCases = () => {
  container
    .bind<ListPhotoUseCase>(LIST_PHOTO_USE_CASE)
    .to(ListPhotoUseCaseImpl);
};
bindUseCases();

//Port to Image
const bindPorts = () => {
  container.bind<ImagePort>(IMAGE_PORT).to(ImageAdapter);
  container.bind<GTMPort>(GTM_PORT).to(GTMAdapter);
};

bindPorts();

export { container };

import React from "react";

import "./style.scss";
import { Button } from "antd";
import { t } from "../../utilities/Message";

// Define your props here
interface CtaProps {
  className?: string;
  onClick?: () => void;
}

const TCta = (props: CtaProps) => {
  return (
    <div className={`Cta ${props.className}`}>
      <p className="font-semibold">{t("app.Landing.CTA.Text")}</p>
      <Button size="middle" className="ml-2" onClick={props.onClick}>
        {t("app.Landing.CTA.Button")}
      </Button>
    </div>
  );
};

TCta.displayName = "TCta";

TCta.defaultProps = {
  className: "",
};

export default TCta;

import { useState, useEffect } from "react";

const publicUrl = `${process.env.REACT_APP_BASE_URL}/api/pub`;

const TENANT_ID_GENERAL = process.env.REACT_APP_TENANT;
export const useGetModels = () => {
  const [dataModels, setDataModels] = useState([]);
  const [loadingModels, setLoading] = useState(false);

  const groupByField = (xs, key) => {
    if (xs) {
      return xs.reduce(function (rv, x) {
        const types = x[key].split(",");
        types.forEach((type) => {
          (rv[type] = rv[type] || []).push(x);
        });
        return rv;
      }, {});
    }
    return xs;
  };

  const getTenantModels = async () => {
    try {
      setLoading(true);
      let response = await fetch(publicUrl + "/tenant/" + TENANT_ID_GENERAL, {
        method: "GET",
      });

      let responseData = await response.json();
      const data = responseData.algorithms;
      let motorsEnabled = groupByField(data, "content_type");
      //Disable locusai for video
      if (motorsEnabled.video) {
        motorsEnabled.video = motorsEnabled.video.filter(
          (motor) => motor.id !== "locusai"
        );
      }

      setDataModels(motorsEnabled);
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTenantModels();
  }, []);

  return [dataModels, loadingModels] as const;
};

import React from "react";

import { CAROUSEL_ITEMS } from "./Constants";
import "./style.scss";
import { Tag } from "antd";

// Define your props here
interface CarouselProps {
  className?: string;
}

const TCarouselV2 = (props: CarouselProps) => {
  return (
    <div
      id="container-2"
      className={`carousel-v2 max-padding  ${props.className}`}
    >
      <div className="text-center m-autor">
        <Tag
          className="mb-1 font-medium-2"
          color="#AEDDD0"
          style={{
            padding: " 5px 15px",
            color: "#222280",
            borderRadius: "20px",
          }}
        >
          Nuestra tecnología
        </Tag>
      </div>
      <h1 className="services__title font-medium-2  text-center">
        Así funciona VerifAI
      </h1>
      {CAROUSEL_ITEMS.map((carousel, index) => (
        <div
          className={
            "carousel-v2__container " + (index % 2 === 0 ? "inverse" : "")
          }
          key={carousel.id}
        >
          <div className="carousel__content">
            <h2 className="text-left ">{carousel.title}</h2>
            <p className="mt-1 text-left services__description">
              {carousel.description}
            </p>
            <p className="mt-1 text-left services__description">
              {carousel.description2}
            </p>
          </div>
          <div className="m-auto">
            <img src={carousel.img} alt="service" width={350} />
          </div>
        </div>
      ))}
    </div>
  );
};

TCarouselV2.displayName = "TCarouselV2";

TCarouselV2.defaultProps = {
  className: "",
};

export default TCarouselV2;

export default class TableList<T = any> {
  content: T[];
  totalPages: number;
  totalElements: number;
}

export enum TableHeaderType {
  WEB = 1,
  TABLET = 2,
  MOBILE = 3,
  ALL = 4,
}

export enum TableHeaderAccesor {
  ACTION = "action",
  STATE = "status",
  SPECIAL = "special",
  TRANSFER_STATE = "transferStatus",
  EXPIRES_TODAY = "expiresToday",
  NEXT_PAYMENT_DATE = "nextPaymentDate",
  EXPIRED_DAYS = "expiredDays",
  CORRELATIVE = "correlative",
}

export class TableHeader {
  Header: string;
  accessor: string;
  type?: number = TableHeaderType.WEB;
}

export class TablePaginationCriteria {
  page?: number;
  items?: number;
  sortColumn?: string;
  sortOrder?: string;
  find?: string;
}

import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./Routes";
import moengage from "@moengage/web-sdk";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import SEO from "../../components/SEO";

function TRouter() {
  const { locale } = useContext(LanguageContext);

  const meta = {
    en: {
      title: "Cybersecurity, Artificial Intelligence and Deepfake | TU VerifAI",
      description:
        "TU VerifAI detects AI manipulation of content. Technological innovation to check if an image, video or text has been generated by AI.",
    },
    es: {
      title: "Ciberseguridad, Inteligencia Artificial y Deepfake | TU VerifAI",
      description:
        "TU VerifAI detecta la manipulación de contenido con IA. Innovación tecnológica para comprobar si una imagen, vídeo o texto ha sido generado por IA.",
    },
  };

  const { title, description } = meta[locale] || meta.es;

  useEffect(() => {
    moengage.initialize({
      app_id: process.env.REACT_APP_MOENGAGE,
      debug_logs: 0,
      cluster: "DC_2",
    });
  }, []);

  return (
    <>
      <SEO title={title} description={description} />
      <Router>
        <Switch>
          {Routes.map((route: any) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={route?.exact}
            />
          ))}
        </Switch>
      </Router>
    </>
  );
}

export default TRouter;

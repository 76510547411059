import React, { createContext, useState, ReactNode, useEffect } from "react";

import { container } from "../../../inject_container/injection";
import { IMAGE_PORT, ImagePort } from "../../../domain/port/inbound/ImagePort";
import { ImageResponse } from "../../../domain/entities/Photo";
import { ApiRespone } from "../../../domain/entities/Api";
import { STATUS_TASK } from "../../components/DropZone/Constants";
import { PAYMENT_STATUS } from "../../utilities/Constant";
import { closeIframe } from "../../utilities/Function";

type WebSocketContextType = {
  isConnected: any;
  uploadData: (
    params: File,
    algorithm_list: string,
    recaptchaToken?: string
  ) => Promise<ImageResponse>;
  onCheckResult: (params: string, accessToken: string) => void;
  data: ApiRespone;
  setData: React.Dispatch<React.SetStateAction<ApiRespone>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
  setError: any;
  retry: number;
  waitingSyncPayment?: boolean;
  setWaitingSyncPayment?: React.Dispatch<React.SetStateAction<boolean>>;
};

type ProviderProps = {
  children: ReactNode;
};

export const VerifaiContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const VerifaiProvider: React.FC<ProviderProps> = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const [isConnected] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);
  const [data, setData] = useState({});
  const [waitingSyncPayment, setWaitingSyncPayment] = useState(false);

  const imageRequest = container.get<ImagePort>(IMAGE_PORT);

  const uploadData = async (params: File, algorithm_list: string) => {
    setLoading(true);
    const response = {};
    try {
      const response = await imageRequest.analyzeImage(
        params,
        algorithm_list,
        ""
      );
      if (!response.id && response?.detail) {
        setError({ message: response?.detail });
        return response;
      }
      if (response) {
        setData(response);
        return response;
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }

    return response;
  };

  const onGetPremiumContent = (task) => {
    return task.payment_details?.payment_status === PAYMENT_STATUS.started;
    // return subTasks.some((task) => task.status > STATUS_TASK.start);
  };

  const onCheckPremiumData = (data) => {
    return data.subtasks.some((task) => task.status > STATUS_TASK.done);
  };

  const checkPayment = (task) => {
    return task.payment_details?.payment_status === PAYMENT_STATUS.payed;
  };

  const onCheckResult = async (params: string, accessToken: string) => {
    setLoading(true);
    try {
      const response = await imageRequest.checkResult(params, accessToken);
      if (response) {
        setData(response);
      }

      if (onCheckPremiumData(response)) {
        setWaitingSyncPayment(false);
      }
      if (checkPayment(response)) {
        closeIframe();
      }
      if (
        response.status !== STATUS_TASK.done ||
        onGetPremiumContent(response)
      ) {
        setRetry(retry + 1);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
    return;
  };

  return React.createElement(VerifaiContext.Provider, {
    value: {
      isConnected,
      uploadData,
      data,
      setData,
      loading,
      setLoading,
      error,
      setError,
      onCheckResult,
      retry,
      waitingSyncPayment,
      setWaitingSyncPayment,
    },
    children,
  });
};

import { injectable } from "inversify";
import { ImagePort } from "../../domain/port/inbound/ImagePort";
import { ImageResponse } from "../../domain/entities/Photo";

const publickURL = `${process.env.REACT_APP_BASE_URL}/api/pub`;
@injectable()
export class ImageAdapter implements ImagePort {
  /**
   * Open the ws connection
   * @returns
   */
  async connected(): Promise<any> {
    try {
      // return await this.getAuth();
    } catch (error) {
      throw new Error("Failed to connecte d");
    }
  }

  /**
   *
   * @param request
   * @returns
   */
  async analyzeImage(
    request: File,
    algorithm_list: string,
    recaptchaToken: string
  ): Promise<ImageResponse> {
    try {
      return await this.uploadFile(request, algorithm_list, recaptchaToken);
    } catch (error) {
      console.log("🚀 ~ ImageAdapter ~ error:", error);
      throw new Error("Failed to upload file");
    }
  }

  async checkResult(
    request: string,
    accessToken: string
  ): Promise<ImageResponse> {
    const token = accessToken || localStorage.getItem("accessToken");
    //TODO refactor request name
    const taskId = request || localStorage.getItem("taskId");

    const response = await fetch(
      publickURL +
        "/verifai/task/guest/" +
        process.env.REACT_APP_TENANT +
        "/" +
        taskId +
        "?token=" +
        token,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      const data = await response.json();
      return data;
    }
    throw new Error("Error check result" + response?.status);
  }

  /**
   *
   * @param request
   * @returns
   */
  async analyzeText(
    request: string,
    algorithm_list: string
  ): Promise<ImageResponse> {
    try {
      return await this.uploadText(request, algorithm_list);
    } catch (error) {
      console.log("🚀 ~ ImageAdapter ~ error:", error);
      // throw new Error("Failed to analyze Text");
    }
  }

  uploadFile = async (
    file: File,
    algorithm_list: string,
    recaptchaToken?: string
  ): Promise<ImageResponse> => {
    //Build the payload using the ID from the current Socket
    var formData = new FormData();
    formData.append("file", file);
    formData.append("content_type", localStorage.getItem("typeFile")); //image/video
    formData.append("tags", "test");
    formData.append("algorithm_list", algorithm_list);

    //Send post service
    const response = await fetch(
      publickURL + "/verifai/analysis/file/" + process.env.REACT_APP_TENANT,
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();
    const { guest_token, id } = data;
    if (guest_token && id) {
      localStorage.setItem("accessToken", guest_token);
      localStorage.setItem("taskId", id);
    }
    return data;
  };

  uploadText = async (text: string, algorithm_list: string) => {
    const payload = {
      mimetype: "test",
      content_type: "text",
      tags: "test",
      algorithm_list: [algorithm_list],
      body: text,
    };

    const response = await fetch(
      publickURL + "/verifai/analysis/json/" + process.env.REACT_APP_TENANT,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    const { guest_token, id } = data;
    if (guest_token && id) {
      localStorage.setItem("accessToken", guest_token);
      localStorage.setItem("taskId", id);
    }
    return data;
  };
}

import { Button } from "antd";
import React from "react";
import MetaData from "../Metadata";
import TProgressBar from "../../../ProgressBar";
import { FilesAvailable } from "../../../../../domain/entities/Api";
import { t } from "../../../../utilities/Message";
import { PAYMENT_STATUS } from "../../../../utilities/Constant";
import TMyReportDetailBody from "../PaymentContentBody";
import { fileSizeStr } from "../../../../../infraestructure/utils/helpers";

const PaymentContent = ({
  doPayment,
  onCancel,
  loading,
  data,
  typeFile,
  isPaymentProgress,
  waitingSyncPayment,
}) => {
  const paymentStatus = data.payment_details?.payment_status;
  const paymentStarted = loading && !data.payment_details;

  if (paymentStatus === PAYMENT_STATUS.payed) {
    return (
      <TMyReportDetailBody
        id="MyReportDetailBody"
        task={data}
        resource={""}
        loading={paymentStarted}
      />
    );
  }

  return (
    <div className="result__body">
      <div className="result__body-content">
        <MetaData typeFile={typeFile} loading={paymentStarted} />
      </div>
      <div className="result__body-info">
        {paymentStarted ? (
          <div className="text-center">
            <TProgressBar />
            {typeFile === FilesAvailable.video ? (
              <p className="mt-1 font-medium-2">
                Estamos calculado el importe del análisis del video
              </p>
            ) : (
              <p className="mt-1 font-medium-2">{t("app.result.loading")}</p>
            )}
            <p className="">{t("app.result.loading_2")}</p>
            <div className="mt-2 full-center d-flex ">
              <Button
                className="text-center"
                style={{ display: "flex" }}
                size="large"
                type="primary"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </div>
          </div>
        ) : (
          <div className="PaymentContent">
            <h3 className="font-medium-2">Resumen de pago</h3>

            <div>
              <p className="mt-1">
                Tamaño del archivo : {fileSizeStr(data.filesize)}{" "}
              </p>
              <p className="mt-1">
                Costo por Mb: {data.payment_details.euro_per_mb}€{" "}
              </p>
              <h4 className="mt-2 mb-0 font-medium-2">Total</h4>
              <p className=" font-large font-regular-2 blue-2">
                {data.payment_details.price}€{" "}
                <small className="font-small blue-2">Impuestos incluidos</small>{" "}
              </p>
              <div className="mt-2 ">
                <Button
                  loading={isPaymentProgress || waitingSyncPayment}
                  size="large"
                  className=" btn-more"
                  type="primary"
                  onClick={doPayment}
                >
                  {waitingSyncPayment
                    ? "Procesando"
                    : paymentStatus === PAYMENT_STATUS.paying
                    ? "Generando Informe"
                    : "Pagar"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentContent;

import React, { useContext, useEffect, useState } from "react";
import { withLanding } from "../../hocs/withLanding";
import TResult from "../../components/Result";
import { useHistory, useParams } from "react-router-dom";

import { VerifaiContext } from "../../context/ws/VerifaiProvider";
import { Result, message } from "antd";

import { ReactSVG } from "react-svg";
import {
  closeIframe,
  generateIframe,
  pathServer,
} from "../../utilities/Function";

import "./styles.scss";
import { page_view, user_interaction } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import ErrorPage from "../ErrorPage";
import { PAYMENT_STATUS, ROUTES_GENERAL } from "../../utilities/Constant";

export const TIMEOUT_SESSION = 4000;
const publicUrl = `${process.env.REACT_APP_BASE_URL}/api/pub`;
const TENANT_ID_GENERAL = process.env.REACT_APP_TENANT;

const ErrorResponse = ({ error, data }) => {
  if (!error) return;

  if (error)
    return (
      <ErrorPage
        error={error}
        className="error-page pt-1 max-width"
        status="500"
        title="Error"
        subTitle={error?.message}
      />
    );

  if (!data?.results?.diffusion?.sucess)
    return (
      <Result
        className="error-page "
        icon={
          <ReactSVG src={pathServer.PATH_IMG + "result/error_prediction.svg"} />
        }
        title="Ups! Algo no ha funcionado"
        subTitle={
          "Asegúrate de que solamente aparezca una cara en tu contenido y si en un video que se entuentre en los primeros 10s del clip."
        }
      />
    );
};

const ResultPage = () => {
  const { id, accessToken } = useParams<{ id: string; accessToken: string }>();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    loading,
    data,
    error,
    onCheckResult,
    retry,

    waitingSyncPayment,
  } = useContext(VerifaiContext);
  const [isPaymentProgress, setIsPaymentProgress] = useState(false);
  const history = useHistory();
  const { locale } = useContext(LanguageContext);

  const checkResult = async () => {
    if (id && accessToken) {
      await onCheckResult(id, accessToken);
    }
  };

  const doPayment = async () => {
    try {
      setIsPaymentProgress(true);
      const response = await fetch(
        publicUrl + "/checkout/payment/" + TENANT_ID_GENERAL + "/" + id,
        {
          method: "GET",
        }
      );
      const responseData = await response.json();
      const iframe = responseData?.url;
      user_interaction("video_analysis");
      if (iframe) {
        generateIframe(iframe);
      } else {
        //Handle error
        messageApi.error(responseData.detail);
      }
    } catch (e) {
      setIsPaymentProgress(false);
      console.log("Error", e);
    }
  };

  useEffect(() => {
    checkResult();
    page_view({ page_type: EVENTS_TGM.report, locale });
  }, []);

  useEffect(() => {
    if (retry > 0) {
      console.log("CALLING RETRY ......." + retry);
      setTimeout(() => {
        checkResult();
      }, TIMEOUT_SESSION);
    }
  }, [retry]);

  useEffect(() => {
    const eventHandler = (event) => {
      const {
        data: { message },
      } = event;
      if (message?.type === "end") {
        closeIframe();
        if (message.data.result === "error") {
          history.push({
            pathname: ROUTES_GENERAL.HOME_PAGE,
            state: {
              errorPayment:
                "El pago no se ha procesado. Vuelve a intentarlo subiendo el video de nuevo",
            },
          });
          window.history.replaceState({}, "");
        }
      }
    };

    window.addEventListener("message", eventHandler);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  return (
    <div className="result-page ">
      {contextHolder}

      <ErrorResponse error={error} data={data} />
      {!error && (
        <TResult
          id={id}
          className="max-width"
          loading={loading}
          data={data}
          doPayment={doPayment}
          isPaymentProgress={isPaymentProgress}
          waitingSyncPayment={waitingSyncPayment}
        />
      )}
    </div>
  );
};

export default withLanding(ResultPage);

/* Los puertos permiten definir la forma en que interactuamos con componentes externos en la capa de dominio.
 En este ejemplo, los puertos nos permiten desacoplar la lógica de negocio de la implementación concreta del
 repositorio y el servicio de correo */

import { ImageResponse } from "../../entities/Photo";

export interface ImagePort {
  connected(): Promise<any>;
  // closeConnection(): Promise<any>;
  analyzeImage(
    request: File,
    algorithm_list: string,
    recaptchaToken?: string
  ): Promise<ImageResponse>;
  checkResult(request: string, accessToken: string): Promise<ImageResponse>;
  analyzeText(request: string, algorithm_list: string): Promise<ImageResponse>;
}

export const IMAGE_PORT = "IMAGE_PORT";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./presentation";
import { ReduxProvider } from "./presentation/configuration/reduxStore/Store";

import reportWebVitals from "./reportWebVitals";

import { LanguageProvider } from "./presentation/context/i18n/LanguageProvider";
import { VerifaiProvider } from "./presentation/context/ws/VerifaiProvider";
import { container } from "./inject_container/injection";
import { GTMPort, GTM_PORT } from "./domain/port/inbound/GTMPort";

import "./resources/sass/base.scss";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const gtm = container.get<GTMPort>(GTM_PORT);
gtm.init();

//TODO: Control ReactStrict Mode depend env
root.render(
  <ReduxProvider>
    <LanguageProvider>
      <VerifaiProvider>
        <App />
      </VerifaiProvider>
    </LanguageProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { injectable } from "inversify";
import UseCase from "../core/UseCase";
import { container } from "../../inject_container/injection";
import TableList, { TablePaginationCriteria } from "../entities/Table";
import Photo from "../entities/Photo";
import {
  PhotoRepository,
  PHOTO_REPOSITORY,
} from "../repositories/PhotoRepository";

export interface ListPhotoUseCase
  extends UseCase<TableList<Photo>, ListPhotoUseCaseParams> {}
export const LIST_PHOTO_USE_CASE = "LIST_PHOTO_USE_CASE";

@injectable()
export class ListPhotoUseCaseImpl implements ListPhotoUseCase {
  async execute(): Promise<TableList<Photo>> {
    let busRepository: PhotoRepository =
      container.get<PhotoRepository>(PHOTO_REPOSITORY);
    let response = await busRepository.listPhotos();
    return response;
  }
}

export class ListPhotoUseCaseParams extends TablePaginationCriteria {
  ownerId: number;
}

import React, { useEffect, useState, Profiler } from "react";
import THeader from "../components/Header";
import { Button, message } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

import "./styles.scss";
import TFooter from "../components/Footer";
import TCtaFooter from "../components/CtaFooter";
import { t } from "../utilities/Message";
import TContactModal from "../components/ForgotPasswordModal";
import TCta from "../components/Cta";
import { user_interaction } from "../hooks/usePageView";

export default function WLandingHome({ children }) {
  const [showButton, setShowButton] = useState(false);
  const [contact, setContact] = useState({ showModal: false, loading: false });
  const [messageApi, contextHolder] = message.useMessage();
  const messagesResponse = {
    error: t("app.Landing.CTA.MessageFailed"),
    success: t("app.Landing.CTA.Message"),
  };
  const onSubmitCTA = () => {
    setContact({ ...contact, loading: true });
    messageApi.open({
      type: "success",
      content: messagesResponse.success,
    });
    setTimeout(() => {
      setContact({ ...contact, showModal: false, loading: false });
      return;
    }, 1000);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const onRender = (
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime
  ) => {
    // console.log(`${id} (${phase}): ${actualDuration}ms`);
    // Puedes realizar acciones adicionales aquí, como enviar datos a un servidor o realizar análisis más avanzados.
  };

  const onHandleEvent = () => {
    user_interaction("how_to_help");
    setContact({ ...contact, showModal: false });
  };
  return (
    <>
      {contextHolder}
      <THeader />
      <TCta onClick={() => setContact({ ...contact, showModal: true })} />
      <Profiler id="MyComponent" onRender={onRender}>
        <main>{children}</main>
      </Profiler>
      <TCtaFooter onClick={() => setContact({ ...contact, showModal: true })} />

      <TFooter />
      {contact.showModal && (
        <TContactModal
          loading={contact.loading}
          visible={contact.showModal}
          onSubmit={onSubmitCTA}
          onCancel={onHandleEvent}
        />
      )}
      <div
        style={{ marginTop: "-80px", marginLeft: "20px" }}
        className="scroll"
      >
        {showButton && (
          <Button
            shape="circle"
            className=""
            type="primary"
            size="large"
            onClick={scrollToTop}
            icon={<ArrowUpOutlined />}
          ></Button>
        )}
      </div>
    </>
  );
}

WLandingHome.propTypes = {};

export const withLanding = (Component) =>
  function (props) {
    return (
      <WLandingHome>
        <Component {...props} />
      </WLandingHome>
    );
  };

import TableList, {
  TablePaginationCriteria,
} from "../../domain/entities/Table";

export default class TableMapper<T = any> {
  list: T[];
  pages: number;
  total: number;

  static mapperToEntity<S = any>(
    mapper: TableMapper,
    mapperToEntity?: (any) => S
  ): TableList<S> {
    let tableList = new TableList<S>();
    tableList.content = mapperToEntity
      ? mapper.list.map((aux) => mapperToEntity(aux))
      : mapper.list;
    tableList.totalPages = mapper.pages;
    tableList.totalElements = mapper.total;
    return tableList;
  }

  static getCriteriaParams(criteria: TablePaginationCriteria): string {
    return `page=${criteria.page}&items=${criteria.items}${
      criteria.sortColumn ? `&sortColumn=${criteria.sortColumn}` : ""
    }${criteria.sortOrder ? `&sortOrder=${criteria.sortOrder}` : ""}${
      criteria.find ? `&find=${criteria.find}` : ""
    }`;
  }
}

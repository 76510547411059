import { injectable } from "inversify";
import { LocalStorageRepository } from "../../domain/repositories/LocalStorageRepository";
import { JSEncrypt } from "jsencrypt";
import { GET_WEB_PRK, GET_WEB_PUK } from "../http/ApiUrls";

export const TOKEN = "token_a";
const ROUTES = "routes_a";
const EMAIL = "email_a";
const PASSWORD = "password_a";
@injectable()
export default class LocalStorageRepositoryImpl
  implements LocalStorageRepository
{
  setAuthToken(token: string) {
    localStorage.setItem(TOKEN, this.encrypt(token));
  }
  getAuthToken(): string {
    return this.decrypt(localStorage.getItem(TOKEN));
  }
  deleteAuthToken() {
    localStorage.removeItem(TOKEN);
  }

  setRoutes(routes: string) {
    localStorage.setItem(ROUTES, this.encrypt(routes));
  }
  getRoutes(): string {
    return this.decrypt(localStorage.getItem(ROUTES));
  }
  deleteRoutes() {
    localStorage.removeItem(ROUTES);
  }

  setCredentials(email: string, password: string) {
    localStorage.setItem(EMAIL, this.encrypt(email));
    localStorage.setItem(PASSWORD, this.encrypt(password));
  }
  getCredentials(): { email: string; password: string } {
    return {
      email: this.decrypt(localStorage.getItem(EMAIL)),
      password: this.decrypt(localStorage.getItem(PASSWORD)),
    };
  }
  deleteCredentials() {
    localStorage.removeItem(EMAIL);
    localStorage.removeItem(PASSWORD);
  }

  cleanSessionStorage() {
    localStorage.removeItem(ROUTES);
    localStorage.removeItem(TOKEN);
  }
  cleanLocalStorage() {
    localStorage.clear();
  }

  private encrypt(body: string) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(GET_WEB_PUK());
    let encryptedBody = encrypt.encrypt(body);
    console.log("LS encrypt", encryptedBody);
    return encryptedBody || "-";
  }

  private decrypt(body: string) {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(GET_WEB_PRK());
    let decryptedBody = decrypt.decrypt(body);
    console.log("LS decrypt", decryptedBody);
    return decryptedBody || "-";
  }
}

import { injectable } from "inversify";
import ApiService from "../http/ApiService";
import ApiUrls from "../http/ApiUrls";
import ApiResponse from "../entities/ApiResponse";
import TableList, {
  TablePaginationCriteria,
} from "../../domain/entities/Table";
import { PhotoRepository } from "../../domain/repositories/PhotoRepository";
import TableMapper from "../entities/TableMapper";
import Photo from "../../domain/entities/Photo";
import { ApiCode, ErrorResponse } from "../../domain/entities/Api";

@injectable()
export default class PhotoRepositoryImpl implements PhotoRepository {
  listPhotos(): Promise<TableList<Photo>> {
    let url = `${ApiUrls.URL_ENDPOINT_PHOTOS()}`;
    return ApiService.jfetch<ApiResponse>(url, {
      method: ApiService.HttpMethod.GET,
    }).then((response: ApiResponse) => {
      if (response.code !== ApiCode.CODE_OK)
        throw new ErrorResponse(response.code, response.dominio);

      return TableMapper.mapperToEntity(response.dominio);
    });
  }
}

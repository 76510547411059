import { JSEncrypt } from "jsencrypt";
import { GET_RSA_PK, GET_WEB_PRK } from "../http/ApiUrls";
import { ErrorResponse } from "../../domain/entities/Api";
import { TOKEN } from "../repositories/LocalStorageRepository";

enum HttpMethod {
  POST = "POST",
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
}

interface HeaderOption {
  key: string;
  value: any;
}

interface HttpHeader {
  [k: string]: any;
}

interface HttpParams {
  method: string;
  body: any;
  headers: HttpHeader;
}

interface Params {
  method: HttpMethod;
  body?: any;
  headers?: HeaderOption[];
}

//Call
const createHttpHeader = async (headers?: Array<HeaderOption>) => {
  let token = decryptToken();
  let httpHeader: HttpHeader =
    token && token
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      : { "Content-Type": "application/json" };
  if (headers) {
    headers.forEach((option) => {
      httpHeader[option.key] = option.value;
    });
  }
  return httpHeader;
};

const jfetch = async <T = any>(
  url: string,
  params: Params,
  isEncrypted: boolean = false
): Promise<T> => {
  let method = params.method;
  let body = params.body;
  let headers = params.headers;
  let httpHeaders = await createHttpHeader(headers);
  let httpParams: HttpParams =
    method === "POST" || method === "PUT"
      ? {
          method: method,
          body:
            httpHeaders["Content-Type"] === "multipart/form-data"
              ? body
              : isEncrypted
              ? encryptBody(body)
              : JSON.stringify(body),
          headers: httpHeaders,
        }
      : { method: method, body: null, headers: httpHeaders };
  let fetchResponse: Response = await fetch(url, httpParams)
    .then((response) => response)
    .catch((err) => err);

  if (fetchResponse.ok) {
    let jsonResponse = await fetchResponse.json();
    let parseResponse = jsonResponse as T;
    return parseResponse;
  } else {
    throw new ErrorResponse(fetchResponse.status + "");
  }
};

const encryptBody = (body: any) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(GET_RSA_PK());
  let encryptedBody = encrypt.encrypt(JSON.stringify(body));
  let response = { data: encryptedBody };
  return JSON.stringify(response);
};

const decryptToken = () => {
  let tokenAS = localStorage.getItem(TOKEN);
  if (!tokenAS) return "";
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(GET_WEB_PRK());
  let decryptedBody = decrypt.decrypt(tokenAS);
  return decryptedBody;
};

export default {
  jfetch,
  HttpMethod,
};
